// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-index-tsx": () => import("./../../../src/pages/search/index.tsx" /* webpackChunkName: "component---src-pages-search-index-tsx" */),
  "component---src-templates-author-page-index-tsx": () => import("./../../../src/templates/AuthorPage/index.tsx" /* webpackChunkName: "component---src-templates-author-page-index-tsx" */),
  "component---src-templates-blog-page-index-tsx": () => import("./../../../src/templates/BlogPage/index.tsx" /* webpackChunkName: "component---src-templates-blog-page-index-tsx" */),
  "component---src-templates-topic-page-index-tsx": () => import("./../../../src/templates/TopicPage/index.tsx" /* webpackChunkName: "component---src-templates-topic-page-index-tsx" */)
}

